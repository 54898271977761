import { useMemo } from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";

import { AppWrapperFallbackLoading } from "@/components/ui/appWrapperFallbackLoading";

import { routesConfig } from "./appRoutes";

export function AppRouter() {
  const router = useMemo(
    () =>
      createBrowserRouter(routesConfig, {
        future: {
          v7_skipActionErrorRevalidation: true,
          v7_fetcherPersist: true,
          v7_normalizeFormMethod: true,
          v7_relativeSplatPath: true,
        },
      }),
    [],
  );

  return (
    <RouterProvider
      router={router}
      fallbackElement={<AppWrapperFallbackLoading title="Starting App..." />}
      future={{ v7_startTransition: true }}
    />
  );
}
