import { useTranslation } from "react-i18next";

import { qmBaseIcons } from "../icons/qmBaseIcons";

import GenericAlert from "./genericAlert";

function RecycleBinAlert() {
  const { t } = useTranslation();
  return <GenericAlert type="danger" alertIcon={<qmBaseIcons.Delete />} heading={t("Recycle bin")} />;
}

export default RecycleBinAlert;
