import { Formik } from "formik";
import React from "react";
import { Button, Card } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { object, ObjectSchema, string } from "yup";

import { IAddressCreateDTO, IAddressDetailsDTO } from "@/generatedCode/pbd-core/pbd-core-api";

import { useFormikAPISubmitter } from "../../../../pbdServices/services/Api/api-formik-submitter";
import { nameofFactory } from "../../../../utils/nameofFactory";
import FormikCustomForm from "../../../shared/components/forms/formik/formikCustomForm";
import { FormikTextInputGroup } from "../../../shared/components/forms/formik/formikTextInput";
import { qmBaseIcons } from "../../../shared/components/icons/qmBaseIcons";
import BaseSettingsFormHeader from "../../components/editPage/baseSettingsFormHeader";

const validationSchema: ObjectSchema<IAddressCreateDTO> = object({
  line1: string().required(),
  line2: string(),
  line3: string(),
  zipCode: string(),
  city: string(),
  country: string(),
  countryCode: string(),
});

interface IProps {
  itemToUpdate?: IAddressDetailsDTO;
  onSubmit: (values: IAddressCreateDTO) => Promise<void>;
  onDelete?: () => void;
  onRestore?: () => void;
  onCancel?: () => void;
  backToIndexUrl?: string;
  children?: React.ReactNode;
}

const nameof = nameofFactory<IAddressCreateDTO>();

export function PlacesForm(props: IProps) {
  const { itemToUpdate, onSubmit, backToIndexUrl, onDelete, onCancel } = props;
  const { t } = useTranslation();
  const [addLine, setAddLine] = React.useState(0);

  function handleAddLineClick() {
    setAddLine((x) => x + 1);
  }

  const submitter = useFormikAPISubmitter<IAddressCreateDTO>((values) => onSubmit(values), [onSubmit]);

  const initialValues: IAddressCreateDTO = {
    line1: itemToUpdate?.line1,
    line2: itemToUpdate?.line2 ?? "",
    line3: itemToUpdate?.line3 ?? "",
    country: itemToUpdate?.country ?? "",
    countryCode: itemToUpdate?.countryCode ?? "",
    zipCode: itemToUpdate?.zipCode ?? "",
    city: itemToUpdate?.city ?? "",
  };

  return (
    <Card className="mb-3">
      <BaseSettingsFormHeader
        itemToUpdate={itemToUpdate}
        onDelete={itemToUpdate && itemToUpdate.connectedObjectsCount == 0 ? onDelete : undefined}
      />
      <Card.Body>
        <Formik initialValues={initialValues} onSubmit={submitter} validationSchema={validationSchema}>
          {(formikBag) => (
            <FormikCustomForm formikBag={formikBag} onCancel={onCancel}>
              <FormikTextInputGroup label={t("Street, house number")} name={nameof("line1")} />
              {addLine > 0 && (
                <FormikTextInputGroup label={`${t("Line")} 2`} placeholder={t("Optional")} name={nameof("line2")} />
              )}
              {addLine > 1 && (
                <FormikTextInputGroup label={`${t("Line")} 3`} placeholder={t("Optional")} name={nameof("line3")} />
              )}
              {addLine < 2 && (
                <Button variant="link" size="sm" onClick={handleAddLineClick} className="p-0">
                  <qmBaseIcons.Plus /> {t("Add line")}
                </Button>
              )}
              <FormikTextInputGroup name={nameof("city")} />
              <FormikTextInputGroup label={t("Zip Code")} name={nameof("zipCode")} />
              <FormikTextInputGroup name={nameof("country")} />
            </FormikCustomForm>
          )}
        </Formik>
        {props.children}
        {backToIndexUrl && <Link to={backToIndexUrl}>{t("Back to index")}</Link>}
      </Card.Body>
    </Card>
  );
}
