import { AppInsightsContext } from "@microsoft/applicationinsights-react-js";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import React from "react";
import { ErrorBoundary } from "react-error-boundary";
import { SWRConfig } from "swr";

import { AuthenticationContextProvider } from "@/components/contexts/authenticationContext";
import { ServiceContextWrapper } from "@/components/contexts/serviceContextWrapper";
import { MainErrorFallback } from "@/components/errors/mainErrorFallback";
import { AppWrapperFallbackLoading } from "@/components/ui/appWrapperFallbackLoading";
import { reactPlugin } from "@/config/applicationInsightsService";
import { queryConfig } from "@/config/reactQuery";
import { swrConfig } from "@/config/swrConfig";

interface AppProviderProps {
  children: React.ReactNode;
}

export function AppProvider({ children }: AppProviderProps) {
  const [queryClient] = React.useState(
    () =>
      new QueryClient({
        defaultOptions: queryConfig,
      }),
  );
  return (
    <React.Suspense fallback={<AppWrapperFallbackLoading title="Loading AppProvider" />}>
      <ErrorBoundary FallbackComponent={MainErrorFallback}>
        <AppInsightsContext.Provider value={reactPlugin}>
          <SWRConfig value={swrConfig}>
            <QueryClientProvider client={queryClient}>
              {import.meta.env && import.meta.env.DEV && <ReactQueryDevtools />}
              <ServiceContextWrapper>
                <AuthenticationContextProvider>{children}</AuthenticationContextProvider>
              </ServiceContextWrapper>
            </QueryClientProvider>
          </SWRConfig>
        </AppInsightsContext.Provider>
      </ErrorBoundary>
    </React.Suspense>
  );
}
