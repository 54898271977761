import { IClaimDTO } from "@/generatedCode/pbd-core/pbd-core-api";
import { BaseKpis } from "@/pbdServices/Models/BaseClasses/BaseKpis";
import { ToDoKpis } from "@/pbdServices/Models/Todos/ToDoKpis";

export class ClaimKpis extends BaseKpis<IClaimDTO> {
  customer?: BaseKpis<IClaimDTO>;
  supplier?: BaseKpis<IClaimDTO>;
  intern?: BaseKpis<IClaimDTO>;
  connectedTodos?: ToDoKpis;
}
