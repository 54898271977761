import * as Handlebars from "handlebars";
import { DateTime, Duration } from "luxon";

import {
  IAuditsControllerClient,
  IClaimsControllerClient,
  ICostDTO,
  ICostsControllerClient,
  IEmployeeIdeaDTO,
  IEmployeeIdeasControllerClient,
  IGoalProgressesControllerClient,
  IGoalsControllerClient,
  IInventoryInspectionsDoneControllerClient,
  IInventoryItemsControllerClient,
  IOpportunitiesControllerClient,
  IOpportunityEvaluationsControllerClient,
  IOrganisationDTO,
  IOrganisationRatingsControllerClient,
  IOrganisationsControllerClient,
  ISettingsControllerClient,
  ITenantDetailsDTO,
  ITenantsControllerClient,
  IToDosControllerClient,
  ITrainingsControllerClient,
  PbdModule,
  ToDoQueryField,
} from "@/generatedCode/pbd-core/pbd-core-api";

import { AuditRoutePaths } from "../../ClientApp/audits/auditRoutePaths";
import { EmployeeRoutePaths } from "../../ClientApp/employees/employeeRoutePaths";
import { IdeaManagementRoutePaths } from "../../ClientApp/ideaManagement/ideaManagementRoutePaths";
import { MaintenanceManagementRoutePaths } from "../../ClientApp/maintenanceManagement/maintenanceManagementRoutePaths";
import { OpportunityRoutePaths } from "../../ClientApp/opportunities/opportunityRoutePaths";
import { TaskManagementRoutePaths } from "../../ClientApp/taskManagement/taskManagementRoutePaths";
import { TrainingRoutePaths } from "../../ClientApp/trainings/trainingRoutePaths";
import { GlobalQmBaseConstants } from "../../Constants/GlobalQmBaseConstants";
import { ClaimRoutePaths } from "../../features/claims/claimRoutePaths";
import { GoalRoutePaths } from "../../features/goals/goalRoutePaths";
import { BaseKpis } from "../../pbdServices/Models/BaseClasses/BaseKpis";
import { TenantKpis } from "../../pbdServices/Models/Tenants/TenantKpis";
import { AuditService } from "../../pbdServices/services/Audits/auditService";
import { AuditKpis } from "../../pbdServices/services/Audits/models/audit-kpis";
import CostService from "../../pbdServices/services/Costs/costService";
import { ICostSum } from "../../pbdServices/services/Costs/models/ICostSum";
import GoalService from "../../pbdServices/services/Goals/goalService";
import { GoalKpis } from "../../pbdServices/services/Goals/models/goal-kpis";
import IdeaManagementReportingService from "../../pbdServices/services/IdeaManagement/ideaManagementReportingService";
import InventoryItemReportingService from "../../pbdServices/services/InventoryItems/inventoryItemReportingService";
import { InventoryItemKpis } from "../../pbdServices/services/InventoryItems/models/inventory-item-kpis";
import { OpportunityKpis } from "../../pbdServices/services/Opportunities/models/opportunity-kpis";
import OpportunityService from "../../pbdServices/services/Opportunities/opportunityService";
import { OrganisationRatingKpis } from "../../pbdServices/services/Organisations/models/Organisation-rating-kpis";
import TenantReportingService from "../../pbdServices/services/Tenants/tenantReportingService";
import { ToDoKpis } from "../../pbdServices/services/ToDos/models/todo-kpis";
import ToDoService from "../../pbdServices/services/ToDos/todoService";
import { TrainingKpis } from "../../pbdServices/services/Trainings/models/training-kpis";
import TrainingService from "../../pbdServices/services/Trainings/trainingService";
import ClaimService from "../../services/claims/claimService";
import { DateTimeLuxonHelpers } from "../../utils/dateTimeLuxonHelpers";
import { ClaimKpis } from "../claims/models/claimKpis";
import RestUtilities from "../restClients/restUtilities";

interface QueryParams {
  createdFrom: DateTime;
  createdTo: DateTime;
  createdFromComparison?: DateTime;
  createdToComparison?: DateTime;
}

export class OrganisationInfo {
  organisation?: IOrganisationDTO;
  managingDirector?: ITenantDetailsDTO;
  dataProtectionOfficer?: ITenantDetailsDTO;
}

export class ReportData extends OrganisationInfo {
  current?: ReportingData;
  compare?: ReportingData;
  today?: string;
}

interface IReportingData {
  costs: ICostDTO[];
  costSums: ICostSum[];
  toDos: ToDoKpis;
  audits: AuditKpis;
  goals: GoalKpis;
  claims: ClaimKpis;
  opportunities: OpportunityKpis;
  ideas: BaseKpis<IEmployeeIdeaDTO>;
  ratings: OrganisationRatingKpis;
  trainings: TrainingKpis;
  inventoryItems: InventoryItemKpis;
  employees: TenantKpis;
}

export class ReportingData implements IReportingData {
  constructor(from: DateTime, to: DateTime, data: IReportingData) {
    this.reportFrom = DateTimeLuxonHelpers.convertUtcToDate(from);
    this.reportTo = DateTimeLuxonHelpers.convertUtcToDate(to);
    this.queryParametersAsString = RestUtilities.getQueryString({ createdFrom: from, createdTo: to });
    this.costSums = data.costSums;
    this.costs = data.costs;
    this.toDos = data.toDos;
    this.audits = data.audits;
    this.goals = data.goals;
    this.claims = data.claims;
    this.opportunities = data.opportunities;
    this.ideas = data.ideas;
    this.ratings = data.ratings;
    this.trainings = data.trainings;
    this.inventoryItems = data.inventoryItems;
    this.employees = data.employees;
  }
  reportFrom: string;
  reportTo: string;
  queryParametersAsString: string;
  costs: ICostDTO[];
  costSums: ICostSum[];
  toDos: ToDoKpis;
  audits: AuditKpis;
  goals: GoalKpis;
  claims: ClaimKpis;
  opportunities: OpportunityKpis;
  ideas: BaseKpis<IEmployeeIdeaDTO>;
  ratings: OrganisationRatingKpis;
  trainings: TrainingKpis;
  inventoryItems: InventoryItemKpis;
  employees: TenantKpis;
}

export default class PlaceholderService {
  auditsApi: IAuditsControllerClient;
  claimsApi: IClaimsControllerClient;
  costsApi: ICostsControllerClient;
  employeeIdeasApi: IEmployeeIdeasControllerClient;
  goalsApi: IGoalsControllerClient;
  goalProgressApi: IGoalProgressesControllerClient;
  inventoryItemsApi: IInventoryItemsControllerClient;
  inventoryInspectionsDoneApi: IInventoryInspectionsDoneControllerClient;
  tenantsApi: ITenantsControllerClient;
  organisationsApi: IOrganisationsControllerClient;
  opportunitiesApi: IOpportunitiesControllerClient;
  opportunityEvaluationsApi: IOpportunityEvaluationsControllerClient;
  organisationRatingsApi: IOrganisationRatingsControllerClient;
  todosApi: IToDosControllerClient;
  trainingsApi: ITrainingsControllerClient;
  settingsApi: ISettingsControllerClient;
  constructor(
    auditsApi: IAuditsControllerClient,
    claimsApi: IClaimsControllerClient,
    costsApi: ICostsControllerClient,
    employeeIdeasApi: IEmployeeIdeasControllerClient,
    goalsApi: IGoalsControllerClient,
    goalProgressApi: IGoalProgressesControllerClient,
    inventoryItemsApi: IInventoryItemsControllerClient,
    inventoryInspectionsDoneApi: IInventoryInspectionsDoneControllerClient,
    tenantsApi: ITenantsControllerClient,
    organisationsApi: IOrganisationsControllerClient,
    todosApi: IToDosControllerClient,
    trainingsApi: ITrainingsControllerClient,
    opportunitiesApi: IOpportunitiesControllerClient,
    opportunityEvaluationsApi: IOpportunityEvaluationsControllerClient,
    organisationRatingsApi: IOrganisationRatingsControllerClient,
    settingsApi: ISettingsControllerClient,
  ) {
    this.auditsApi = auditsApi;
    this.claimsApi = claimsApi;
    this.costsApi = costsApi;
    this.employeeIdeasApi = employeeIdeasApi;
    this.goalsApi = goalsApi;
    this.goalProgressApi = goalProgressApi;
    this.inventoryItemsApi = inventoryItemsApi;
    this.inventoryInspectionsDoneApi = inventoryInspectionsDoneApi;
    this.organisationsApi = organisationsApi;
    this.opportunitiesApi = opportunitiesApi;
    this.opportunityEvaluationsApi = opportunityEvaluationsApi;
    this.organisationRatingsApi = organisationRatingsApi;
    this.tenantsApi = tenantsApi;
    this.todosApi = todosApi;
    this.trainingsApi = trainingsApi;
    this.settingsApi = settingsApi;
  }

  private get _excludedKeys() {
    return ["loc", "invalid"];
  }

  recursiveKeys(dataObject: Record<string, any>, initialKeys?: string[], prefixKey?: string): string[] {
    const localPrefix = prefixKey ? `${prefixKey}.` : "";
    const localKeys = initialKeys ?? [];
    Object.keys(dataObject)
      .filter((x) => !this._excludedKeys.includes(x))
      .forEach((key) => {
        const value = dataObject[key];
        const propPrefix = `${localPrefix}${key}`;
        if (typeof value !== "object") {
          if (prefixKey) {
            localKeys.push(propPrefix);
          } else {
            localKeys.push(key);
          }
        } else if (value == null || value == undefined) {
          localKeys.push(propPrefix);
        } else if (typeof value == "object") {
          if (Array.isArray(value)) {
            localKeys.push(propPrefix);
            localKeys.push(`${propPrefix}.length`);
            if (value.length > 0) {
              this.recursiveKeys(value[0], localKeys, `${propPrefix}[0]`);
            }
          } else if (DateTime.isDateTime(value) || Duration.isDuration(value)) {
            localKeys.push(propPrefix);
          } else {
            this.recursiveKeys(value, localKeys, propPrefix);
          }
        } else {
          console.warn("Unknown case for keys generation");
        }
      });
    return localKeys;
  }

  async getPlaceholderKeys(): Promise<string[]> {
    const keys: string[] = [];
    const orgInfo = await this.getOrganisationInfo();
    const keysOrgInfo = this.recursiveKeys(orgInfo);
    keys.push(...keysOrgInfo);
    const currentData = await this.getDataForHandlebarReport({
      createdFrom: DateTime.now(),
      createdTo: DateTime.now(),
    });
    const keysReport = this.recursiveKeys(currentData);
    for (const element of keysReport) {
      keys.push(`current.${element}`);
    }
    for (const element of keysReport) {
      keys.push(`compare.${element}`);
    }
    return keys;
  }

  async getOrganisationInfo(): Promise<OrganisationInfo> {
    const basicAdminData = await this.settingsApi.getBasicAdminData();
    const respObject = new OrganisationInfo();
    if (basicAdminData) {
      if (basicAdminData.managingDirectorId) {
        const mdResp = await this.tenantsApi.getById(basicAdminData.managingDirectorId);
        respObject.managingDirector = mdResp;
      }
      if (basicAdminData.dataProtectionOfficerId) {
        const dpResp = await this.tenantsApi.getById(basicAdminData.dataProtectionOfficerId);
        respObject.dataProtectionOfficer = dpResp;
      }
      if (basicAdminData.organisationId) {
        const organisationResp = await this.organisationsApi.getById(basicAdminData.organisationId);
        respObject.organisation = organisationResp;
      }
    }
    return respObject;
  }
  /**
   *
   * @param query
   * @param placeholdersInText {{placeholder}}
   */
  async getDataForHandlebarReport(query: QueryParams) {
    const costs = await this.costsApi.getAllQuery({
      createdFrom: query.createdFrom,
      createdTo: query.createdTo,
    });
    const queryParametersAsString = RestUtilities.getQueryString({
      createdFrom: query.createdFrom,
      createdTo: query.createdTo,
    });
    const todos = await this.todosApi.getAllQuery({ createdFrom: query.createdFrom, createdTo: query.createdTo });
    const toDoKpis = ToDoService.getKpis(
      todos,
      costs.filter((x) => x.keyName == "ToDo"),
      TaskManagementRoutePaths.IndexTodoPage + queryParametersAsString,
    );
    const audits = await this.auditsApi.getAllQuery({ createdFrom: query.createdFrom, createdTo: query.createdTo });
    const todosConnectedToAudits = await this.todosApi.getAllQuery({
      createdFrom: query.createdFrom,
      createdTo: query.createdTo,
      connectedTo: [PbdModule.AuditManagement],
      fields: [ToDoQueryField.AuditManagement],
    });
    const auditKpis = AuditService.getKpis(
      audits,
      todosConnectedToAudits,
      AuditRoutePaths.IndexPage + queryParametersAsString,
    );

    const goals = await this.goalsApi.getAllQuery({ createdFrom: query.createdFrom, createdTo: query.createdTo });
    const todosConnectedToGoals = await this.todosApi.getAllQuery({
      createdFrom: query.createdFrom,
      createdTo: query.createdTo,
      connectedTo: [PbdModule.GoalManagement],
      fields: [ToDoQueryField.GoalManagement],
    });
    const goalProgresses = await this.goalProgressApi.getAllQuery({
      createdFrom: query.createdFrom,
      createdTo: query.createdTo,
    });
    const goalKpis = GoalService.getKpis(
      goals,
      todosConnectedToGoals,
      goalProgresses,
      costs.filter((x) => x.keyName == "Goal"),
      GoalRoutePaths.IndexPage + queryParametersAsString,
    );

    const trainings = await this.trainingsApi.getAllQuery({
      createdFrom: query.createdFrom,
      createdTo: query.createdTo,
    });
    const todosConnectedToTrainings = await this.todosApi.getAllQuery({
      createdFrom: query.createdFrom,
      createdTo: query.createdTo,
      connectedTo: [PbdModule.TrainingManagement],
      fields: [ToDoQueryField.TrainingManagement],
    });
    const trainingKpis = TrainingService.getKpis(
      trainings,
      todosConnectedToTrainings,
      costs.filter((x) => x.keyName == "Training"),
      TrainingRoutePaths.IndexPage + queryParametersAsString,
    );

    const claims = await this.claimsApi.getAllQuery({
      createdFrom: query.createdFrom,
      createdTo: query.createdTo,
    });
    const todosConnectedToClaims = await this.todosApi.getAllQuery({
      createdFrom: query.createdFrom,
      createdTo: query.createdTo,
      connectedTo: [PbdModule.ClaimManagement],
      fields: [ToDoQueryField.ClaimManagement],
    });

    const claimKpis = ClaimService.getKpis(
      claims,
      todosConnectedToClaims,
      costs.filter((x) => x.keyName == "Claim"),
      ClaimRoutePaths.IndexPage + queryParametersAsString,
    );

    const opportunitiesResp = await this.opportunitiesApi.getAllQuery({
      createdFrom: query.createdFrom,
      createdTo: query.createdTo,
    });
    const todosConnectedToOpportunities = await this.todosApi.getAllQuery({
      createdFrom: query.createdFrom,
      createdTo: query.createdTo,
      connectedTo: [PbdModule.OpportunityManagement],
      fields: [ToDoQueryField.OpportunityManagement],
    });
    const opportunityEvaluations = await this.opportunityEvaluationsApi.getAllQuery({
      createdFrom: query.createdFrom,
      createdTo: query.createdTo,
    });
    const opportunityKpis = OpportunityService.getKpis(
      opportunitiesResp,
      todosConnectedToOpportunities,
      opportunityEvaluations,
      OpportunityRoutePaths.IndexPage + queryParametersAsString,
    );
    const ideas = await this.employeeIdeasApi.getAllQuery({
      submittedFrom: query.createdFrom,
      submittedTo: query.createdTo,
    });

    const ideaKpis = IdeaManagementReportingService.getKpis(
      ideas,
      IdeaManagementRoutePaths.IndexPage + queryParametersAsString,
    );
    const ratings = await this.organisationRatingsApi.getAllQuery({
      doneAtFrom: query.createdFrom,
      doneAtTo: query.createdTo,
    });

    const ratingKpis = new OrganisationRatingKpis(ratings);
    const inventoryItems = await this.inventoryItemsApi.getAll();
    const inspectionsDoneResp = await this.inventoryInspectionsDoneApi.getAllQuery({
      doneAtFrom: query.createdFrom,
      doneAtTo: query.createdTo,
    });

    const inventoryItemKpis = InventoryItemReportingService.getKpis(
      inventoryItems,
      inventoryItems.filter((x) => x.createdAt >= query.createdFrom && x.createdAt <= query.createdTo),
      inspectionsDoneResp,
      MaintenanceManagementRoutePaths.IndexPage + queryParametersAsString,
    );

    const tenants = (
      await this.tenantsApi.getAllQuery({
        isEmployee: true,
        createdFrom: query.createdFrom,
        createdTo: query.createdTo,
        pageSize: GlobalQmBaseConstants.DefaultPageSize_DuringMigration,
      })
    ).data;
    const employees = TenantReportingService.getKpis(tenants, EmployeeRoutePaths.IndexPage);

    const data2 = new ReportingData(query.createdFrom, query.createdTo, {
      audits: auditKpis,
      claims: claimKpis,
      costs,
      costSums: CostService.getSumOfCosts(costs),
      employees,
      goals: goalKpis,
      ideas: ideaKpis,
      inventoryItems: inventoryItemKpis,
      opportunities: opportunityKpis,
      ratings: ratingKpis,
      toDos: toDoKpis,
      trainings: trainingKpis,
    });
    return data2;
  }

  compileHandlebars(content: string, someData: unknown) {
    const template = Handlebars.compile(content);
    return template(someData);
  }

  getHighlightedText(content: string, reportKeys: string[]): string {
    const regex = new RegExp("\\{\\{([^}]+)\\}\\}", "g");
    return content.replace(regex, (match) => {
      if (reportKeys.includes(match.replace("{{", "").replace("}}", ""))) {
        return `<mark style="color:green">${match}</mark>`;
      }
      return `<mark>${match}</mark>`;
    });
  }
}
