import RestUtilities from "@/services/restClients/restUtilities";

export const paths = {
  home: {
    path: "/",
    getHref: () => "/",
  },

  error: {
    path: "/Error",
    getHref: (code?: string | number) => `/Error/${RestUtilities.getQueryString({ code })}`,
  },

  auth: {
    accessDenied: {
      path: "/Home/AccessDenied",
      getHref: (roles?: string[], path?: string) =>
        `/Home/AccessDenied/${RestUtilities.getQueryString({ requiredRoles: roles, returnUrl: path })}`,
    },
    login: {
      path: "/Account/Login",
      getHref: (redirectTo?: string | null) =>
        `/Account/Login/${redirectTo ? `?returnUrl=${encodeURIComponent(redirectTo)}` : ""}`,
    },
  },

  app: {
    root: {
      path: "/Home",
      getHref: () => "/Home",
    },
  },

  products: {
    root: {
      path: "/Products",
      getHref: () => "/Products",
    },
    index: {
      path: "/Products/Index",
      getHref: () => "/Products/Index",
    },
    create: {
      path: "/Products/Create",
      getHref: () => "/Products/Create",
    },
    details: {
      path: "/Products/Details/:id",
      getHref: (id: number) => `/Products/Details/${id}`,
    },
  },
} as const;
