import { uniq } from "lodash";
import { Navigate, useLocation } from "react-router-dom";

import { paths } from "@/config/paths";
import { PbdModule } from "@/generatedCode/pbd-core/pbd-core-api";
import { useCurrentAppHook } from "@/hooks/useCurrentAppHook";

import { AccountRoutePaths } from "../../ClientApp/account/accountRoutePaths";
import { getModuleRoleNames } from "../../pbdServices/services/Authz/authService";

import { useAuthenticationContext } from "./authenticationContext";

const appsWithoutPermissions = [PbdModule.None, PbdModule.Startpage, PbdModule.Profile];

interface IProps {
  children: React.ReactNode;
}

export function AuthorizationWrapper(props: IProps) {
  const { children } = props;
  const { user, meDTO } = useAuthenticationContext();
  const { currentApp } = useCurrentAppHook();
  const location = useLocation();

  if (
    !meDTO.isAuthenticated &&
    location.pathname != AccountRoutePaths.ActivateProfilePage &&
    location.pathname != AccountRoutePaths.ConfirmEmail
  ) {
    return <Navigate to={paths.auth.login.getHref(location.pathname)} replace />;
  }
  if (
    user.isProfileMissing &&
    location.pathname != AccountRoutePaths.ActivateProfilePage &&
    !location.pathname.startsWith(paths.error.path) &&
    !location.pathname.startsWith(paths.auth.accessDenied.path)
  ) {
    return <Navigate to={AccountRoutePaths.ActivateProfilePage} replace />;
  }

  if (!appsWithoutPermissions.includes(currentApp)) {
    const rolesObj = getModuleRoleNames(currentApp);
    const roles = [rolesObj.standardRole, rolesObj.adminRole];
    const uniqueRoles = uniq(roles.filterMap((x) => x));
    if (!user.hasSufficientAppRights(currentApp)) {
      const redirectTo = paths.auth.accessDenied.getHref(uniqueRoles, location.pathname);
      return <Navigate to={redirectTo} replace />;
    }
  }

  return children;
}

export function ProtectedRoute({ children }: { children: React.ReactNode }) {
  const { user, meDTO } = useAuthenticationContext();
  const location = useLocation();

  if (!meDTO.isAuthenticated) {
    console.log({
      pathname: location.pathname,
      redirectTo: paths.auth.login.getHref(location.pathname),
    });
    return <Navigate to={paths.auth.login.getHref(location.pathname)} replace />;
  }

  return children;
}
