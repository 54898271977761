import { chain, countBy } from "lodash";
import { DateTime } from "luxon";

import { SearchFilterTypes } from "@/ClientApp/shared/components/genericSearchFilter/availableSearchFilters";
import {
  ClaimCreateDTO,
  ClaimEditDTO,
  ClaimType,
  ConnectDefectsDTO,
  ConnectEntityCommand,
  ConnectProductDTO,
  IClaimDTO,
  IClaimsControllerClient,
  ICostDTO,
  ICustomField,
  IToDoDTO,
  PbdModule,
  PbdStatus,
} from "@/generatedCode/pbd-core/pbd-core-api";
import { ValidationResultDescriber } from "@/pbdServices/Models/Shared/validation-result-describer";
import { WithWarnings } from "@/pbdServices/Models/Shared/with-warnings";
import { CopyForm, CopyIncludedOption } from "@/pbdServices/services/copy/copyService";
import CostService, { WithCostsOptional } from "@/pbdServices/services/Costs/costService";
import ExportService from "@/pbdServices/services/Export/exportService";
import ToDoService from "@/pbdServices/services/ToDos/todoService";
import { DateTimeLuxonHelpers } from "@/utils/dateTimeLuxonHelpers";
import StringHelpers from "@/utils/stringHelpers";

import { IClaimConnectedElements } from "./models/claimConnectedElements";
import { ClaimKpis } from "./models/claimKpis";

export default class ClaimService {
  #claimsApi: IClaimsControllerClient;
  constructor(claimsApi: IClaimsControllerClient) {
    this.#claimsApi = claimsApi;
  }

  async getConnectedElements(id: number): Promise<IClaimConnectedElements> {
    const connectedTodos = await this.#claimsApi.getConnectedToDos(id);
    const connectedDefects = await this.#claimsApi.getDefectsForClaim(id);
    const salesOrders = await this.#claimsApi.getSalesOrdersForClaims(id);
    const purchaseOrders = await this.#claimsApi.getPurchaseOrdersForClaims(id);
    const files = await this.#claimsApi.getConnectedFiles(id);
    return { connectedTodos, connectedDefects, salesOrders, purchaseOrders, files };
  }

  static getKpis(all: IClaimDTO[], connectedTodos: IToDoDTO[], costs: ICostDTO[], totalUrl?: string) {
    ClaimService.mapCosts(all, costs);
    const kpis = new ClaimKpis(all, totalUrl);
    kpis.intern = new ClaimKpis(all.filter((x) => x.claimType == ClaimType.Intern));
    kpis.customer = new ClaimKpis(all.filter((x) => x.claimType == ClaimType.Customer));
    kpis.supplier = new ClaimKpis(all.filter((x) => x.claimType == ClaimType.Supplier));
    kpis.connectedTodos = ToDoService.getKpis(connectedTodos);
    return kpis;
  }

  static getWarning(itemNotMapped: IClaimDTO, customFields: ICustomField[]) {
    const item = itemNotMapped as WithWarnings<IClaimDTO>;
    item.warnings = [];
    if (item.status != PbdStatus.Completed && item.deadline && DateTimeLuxonHelpers.inPast(item.deadline)) {
      item.warnings.push(ValidationResultDescriber.deadlineExpired());
    }

    const requiredCustomFields = customFields.filter((x) => x.isRequired);
    let missingCustomField = false;
    for (const cf of requiredCustomFields) {
      if (item.customFields?.find((x) => x.id === cf.id) === undefined) {
        missingCustomField = true;
      }
    }
    if (missingCustomField) {
      item.warnings.push(ValidationResultDescriber.requiredCustomFieldMissing());
    }
    return item;
  }

  static getWarnings(array: IClaimDTO[], customFields: ICustomField[]) {
    for (const elem of array) {
      ClaimService.getWarning(elem, customFields);
    }
    return array;
  }

  static mapCosts(array: IClaimDTO[], costs: ICostDTO[]) {
    return array.map((claim) => {
      const costsForClaim = costs.filter((x) => x.keyValues == claim.id.toString());
      return {
        ...claim,
        costs: costsForClaim,
        costSum: CostService.getSumOfCosts(costsForClaim),
      };
    });
  }

  //TODO2
  static exportToCsv(items: WithCostsOptional<IClaimDTO>[], customFields: ICustomField[]) {
    ExportService.exportCSV("Claims", items, (x) => {
      const cfMapped = ExportService.getCustomFields(x.customFields ?? [], customFields);
      return {
        id: x.id,
        title: x.title,
        status: x.status,
        claimType: x.claimType,
        responsible: x.responsible?.fullName,
        description: StringHelpers.stripHtmlFromString(x.description ?? ""),
        createdAt: x.createdAt,
        createdById: x.createdById,
        costs: x.costs ? CostService.convertToString(x.costs) : "",
        lastUpdatedAt: x.lastUpdatedAt,
        claimedAt: x.claimedAt,
        claimedBy: x.claimedBy?.fullName,
        claimedByOrganisation: x.organisation?.title,
        claimIsJustified: x.claimIsJustified,
        satisfactionVia: x.satisfactionVia,
        ...cfMapped,
      };
    });
  }

  static getClaimsByOrganisation(items: IClaimDTO[]) {
    const organisations = items.filterMap((x) => x.organisation);
    const uniqueCount = countBy(organisations, (x) => x.id);
    const data = chain(uniqueCount)
      .map(function (cnt, resp) {
        const id = Number(resp);
        return {
          id,
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          organisation: organisations.find((x) => x.id === id)!,
          count: cnt,
        };
      })
      .sortBy((x) => x.count)
      .value();
    return data;
  }

  async copyClaimAsync(dto: CopyForm, item: IClaimDTO, responsibleId: number) {
    const resp = await this.#claimsApi.create(
      new ClaimCreateDTO({
        title: dto.title,
        deadline: dto.options.deadline ? item.deadline : DateTime.now(),
        claimType: item.claimType,
        claimedAt: item.claimedAt,
        description: dto.options.description ? item.description : "",
        responsibleId: item.responsible?.id ?? responsibleId,
        tags: dto.options.tags ? item.tags?.map((x) => x.title) : undefined,
        warningTime: item.warningTime,
        claimedById: item.claimedBy?.id,
        organisationId: item.organisation?.id,
        isPrivate: false,
      }),
    );
    if (dto.options.customFields) {
      if (item.customFields != undefined) {
        for (const cf of item.customFields) {
          await this.#claimsApi.addCustomField(resp.id, cf);
        }
      }
    }
    if (dto.options.defects) {
      const allDefects = await this.#claimsApi.getDefectsForClaim(item.id);
      const connectDefect = new ConnectDefectsDTO({
        defectIds: allDefects.map((x) => x.id),
      });
      await this.#claimsApi.connectDefects(resp.id, connectDefect);
    }

    if (dto.options.products) {
      const allProducts = await this.#claimsApi.getProductsForClaim(item.id);
      for (const product of allProducts) {
        const connectProduct = new ConnectProductDTO(product);
        await this.#claimsApi.connectProducts(resp.id, [connectProduct]);
      }
    }
    if (dto.options.todos) {
      const allTodos = await this.#claimsApi.getConnectedToDos(item.id);
      const connectedTodo = new ConnectEntityCommand({
        connectFrom: item.type,
        connectFromId: item.id,
        connectTo: PbdModule.ToDoManagement,
        connectToIds: allTodos.map((x) => x.id),
        commandId: "connect",
      });
      await this.#claimsApi.connectSomething(resp.id, connectedTodo);
    }

    await this.#claimsApi.edit(
      resp.id,
      new ClaimEditDTO({
        id: item.id,
        title: item.title,
        satisfactionDescription: item.satisfactionDescription,
        claimedAt: item.claimedAt,
        deadline: item.deadline,
        warningTime: item.warningTime,
        claimType: item.claimType,
        claimedById: item.claimedBy?.id,
        organisationId: item.organisation?.id,
        isJustified: item.claimIsJustified,
        isJustifiedDescription: item.justifiedDescription,
        satisfactionVia: item.satisfactionVia,
      }),
    );

    return resp;
  }

  static get availableFilter() {
    return [
      SearchFilterTypes.Status,
      SearchFilterTypes.Tags,
      SearchFilterTypes.CreatedAt,
      SearchFilterTypes.ClaimedAt,
      SearchFilterTypes.Deadline,
      SearchFilterTypes.ClaimType,
      SearchFilterTypes.Organisation,
      SearchFilterTypes.Responsible,
      SearchFilterTypes.IsDeleted,
      SearchFilterTypes.CustomField,
      SearchFilterTypes.CreatedBy,
      SearchFilterTypes.DoneAt,
      SearchFilterTypes.claimIsJustified,
      SearchFilterTypes.ProductId,
    ];
  }

  static get copyModalOptions(): CopyIncludedOption[] {
    return ["tags", "deadline", "description", "todos", "products", "defects", "defects"];
  }
}
