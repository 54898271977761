import { isRouteErrorResponse, useParams, useRouteError, useSearchParams } from "react-router-dom";

import ErrorPageLayout from "../../../components/layouts/errors/errorPageLayout";

import ErrorPageContentDefault from "./errorPageContentDefault";

interface IProps {
  code?: string;
}

export function ErrorPage(props: IProps) {
  const params = useParams<{ code?: string }>();
  const [searchParams] = useSearchParams();
  const error = useRouteError();

  const codeOverall = isRouteErrorResponse(error)
    ? error.status.toString()
    : (searchParams.get("code") ?? params.code ?? props.code ?? "Unknown");

  return (
    <ErrorPageLayout>
      <ErrorPageContentDefault code={codeOverall} />
    </ErrorPageLayout>
  );
}
