import React from "react";
import { Outlet } from "react-router-dom";
import { QueryParamProvider } from "use-query-params";
import { ReactRouter6Adapter } from "use-query-params/adapters/react-router-6";

import { AppContextProvider } from "@/components/contexts/appContext";
import { AuthorizationWrapper } from "@/components/contexts/authorizationWrapper";
import { CustomHelmetContextProvider } from "@/components/contexts/customHelmetContext";
import { AppWrapperFallbackLoading } from "@/components/ui/appWrapperFallbackLoading";

export function Root() {
  return (
    <QueryParamProvider adapter={ReactRouter6Adapter}>
      <AuthorizationWrapper>
        <CustomHelmetContextProvider>
          <React.Suspense fallback={<AppWrapperFallbackLoading />}>
            <AppContextProvider>
              <Outlet />
            </AppContextProvider>
          </React.Suspense>
        </CustomHelmetContextProvider>
      </AuthorizationWrapper>
    </QueryParamProvider>
  );
}
