import { Container } from "react-bootstrap";
import { ErrorBoundary } from "react-error-boundary";
import { Outlet } from "react-router-dom";

import { useToggle } from "@/hooks/useToggle";

import NavbarComponent from "../../ClientApp/shared/components/navbar/navbarComponent";
import SidebarComponent from "../../ClientApp/shared/components/sidebar/sidebarComponent";

import { ErrorFallback } from "./errors/errorFallback";

/**
 * Layout to be used for normal pages with sidebar and navbar.
 * @param props
 * @returns
 */
function LayoutMain() {
  const [sidebarToggled, toggleSidebar] = useToggle();
  return (
    <>
      <NavbarComponent toggleSidebar={toggleSidebar} sidebarToggled={sidebarToggled} />
      {/* <NavbarComponent toggleSidebar={toggleSidebar} sidebarToggled={sidebarToggled} /> */}
      <div className={`d-flex bg-body min-vh-100${sidebarToggled ? " toggled" : ""}`} id="wrapper">
        <SidebarComponent />
        <div id="page-content-wrapper">
          <Container fluid className="p-4" id="mainQmBaseContainer">
            <ErrorBoundary FallbackComponent={ErrorFallback}>
              <Outlet />
            </ErrorBoundary>
          </Container>
        </div>
      </div>
      {/* <AutoJoyRide /> */}
    </>
  );
}
export default LayoutMain;
