import { PbdModule } from "@/generatedCode/pbd-core/pbd-core-api";

import { AbsencePlannerRoutePaths } from "../ClientApp/absencePlanner/absencePlannerRoutePaths";
import { AdminRoutePaths } from "../ClientApp/admin/adminRoutePaths";
import { ArticleRoutePaths } from "../ClientApp/articles/articleRoutePaths";
import { AuditRoutePaths } from "../ClientApp/audits/auditRoutePaths";
import { CrmRoutePaths } from "../ClientApp/crm/crmRoutePaths";
import { DefectRoutePaths } from "../ClientApp/defects/defectRoutePaths";
import { EmployeeRoutePaths } from "../ClientApp/employees/employeeRoutePaths";
import { FormRoutePaths } from "../ClientApp/forms/formRoutePaths";
import { IdeaManagementRoutePaths } from "../ClientApp/ideaManagement/ideaManagementRoutePaths";
import { MaintenanceManagementRoutePaths } from "../ClientApp/maintenanceManagement/maintenanceManagementRoutePaths";
import { OpportunityRoutePaths } from "../ClientApp/opportunities/opportunityRoutePaths";
import { QualificationMatrixRoutePaths } from "../ClientApp/qualificationMatrix/qualificationMatrixRoutePaths";
import { QualityMonitorRoutePaths } from "../ClientApp/qualityMonitor/qualityMonitorRoutePaths";
import { SettingsRoutePaths } from "../ClientApp/settings/settingsRoutePaths";
import { StoreRoutePaths } from "../ClientApp/store/storeRoutePaths";
import { TaskManagementRoutePaths } from "../ClientApp/taskManagement/taskManagementRoutePaths";
import { TrainingRoutePaths } from "../ClientApp/trainings/trainingRoutePaths";
import { ClaimRoutePaths } from "../features/claims/claimRoutePaths";
import { GoalRoutePaths } from "../features/goals/goalRoutePaths";
import { ProductRoutePaths } from "../features/products/productRoutePaths";
import { ModuleUiInfoDefinition } from "./ModuleUiInfo";

export const moduleUiData: ModuleUiInfoDefinition[] = [
  {
    pbdModule: PbdModule.AuditManagement,
    backgroundClassName: "qmBaseDarkToLightBlueGradient",
    iconClassName: "icon-qmBase-Audits",
    list: AuditRoutePaths.IndexPage,
    create: AuditRoutePaths.CreatePage,
    edit: AuditRoutePaths.EditPage,
    homePage: AuditRoutePaths.HomePage,
    dashboard: QualityMonitorRoutePaths.Audits,
    settings: SettingsRoutePaths.AuditManagementHome,
  },
  {
    pbdModule: PbdModule.Employees,
    backgroundClassName: "littleLeafGradient",
    iconClassName: "icon-qmBase-Employees",
    list: EmployeeRoutePaths.IndexPage,
    create: EmployeeRoutePaths.CreatePage,
    edit: EmployeeRoutePaths.EditPage,
    homePage: EmployeeRoutePaths.HomePage,
    dashboard: QualityMonitorRoutePaths.Employees,
    settings: SettingsRoutePaths.EmployeesHome,
  },
  {
    pbdModule: PbdModule.DocumentManagement,
    backgroundClassName: "steelGrayGradient",
    iconClassName: "icon-qmBase-DocumentManagement",
    create: ArticleRoutePaths.CreatePage(PbdModule.DocumentManagement),
    edit: ArticleRoutePaths.EditPage(PbdModule.DocumentManagement),
    homePage: ArticleRoutePaths.HomePage(PbdModule.DocumentManagement),
    list: ArticleRoutePaths.IndexPage(PbdModule.DocumentManagement),
    recent: ArticleRoutePaths.RecentPage(PbdModule.DocumentManagement),
    dashboard: QualityMonitorRoutePaths.Documents,
  },
  {
    pbdModule: PbdModule.ProjectAndTaskManagement,
    backgroundClassName: "aminGradient",
    iconClassName: "icon-qmBase-ProjectAndTaskManagement",
    create: TaskManagementRoutePaths.SelectPage,
    homePage: TaskManagementRoutePaths.HomePage,
    dashboard: QualityMonitorRoutePaths.ProjectsAndTasks,
    settings: SettingsRoutePaths.TaskManagementHome,
  },
  {
    pbdModule: PbdModule.ToDoManagement,
    backgroundClassName: "aminGradient",
    iconClassName: "icon-qmBase-ProjectAndTaskManagement",
    edit: TaskManagementRoutePaths.EditTodoPage,
    create: TaskManagementRoutePaths.CreateToDoPage,
    homePage: TaskManagementRoutePaths.HomePage,
  },
  {
    pbdModule: PbdModule.GoalManagement,
    iconClassName: "icon-qmBase-Goals",
    backgroundClassName: "qmBaseDarkToLightBlueGradient",
    create: GoalRoutePaths.CreatePage,
    edit: GoalRoutePaths.EditPage,
    homePage: GoalRoutePaths.HomePage,
    list: GoalRoutePaths.IndexPage,
    dashboard: QualityMonitorRoutePaths.Goals,
    // settings: SettingsRoutePaths.GoalHome,
    settings: SettingsRoutePaths.AppSettingsHomePage.replace(":pbdModule", PbdModule.GoalManagement),
  },
  {
    pbdModule: PbdModule.CustomerSatisfactionManagement,
    iconClassName: "icon-qmBase-Customers",
    backgroundClassName: "qmBaseDarkToLightBlueGradient",
  },
  {
    pbdModule: PbdModule.IdeaManagement,
    iconClassName: "icon-qmBase-IdeaManagement",
    backgroundClassName: "mubeaBlueGreyGradient",
    create: IdeaManagementRoutePaths.CreatePage,
    edit: IdeaManagementRoutePaths.DetailsPage,
    homePage: IdeaManagementRoutePaths.HomePage,
    list: IdeaManagementRoutePaths.IndexPage,
    dashboard: QualityMonitorRoutePaths.EmployeeIdeas,
    settings: SettingsRoutePaths.AppSettingsHomePage.replace(":pbdModule", PbdModule.IdeaManagement),
  },
  {
    pbdModule: PbdModule.SupplierSatisfactionManagement,
    iconClassName: "icon-qmBase-Suppliers",
    backgroundClassName: "qmBaseDarkToLightBlueGradient",
  },
  {
    pbdModule: PbdModule.TrainingManagement,
    iconClassName: "icon-qmBase-Trainings",
    backgroundClassName: "aquaMarineGradient",
    create: TrainingRoutePaths.CreatePage,
    edit: TrainingRoutePaths.EditPage,
    homePage: TrainingRoutePaths.HomePage,
    list: TrainingRoutePaths.IndexPage,
    dashboard: QualityMonitorRoutePaths.Trainings,
    settings: SettingsRoutePaths.TrainingsManagementHome,
  },
  {
    pbdModule: PbdModule.ClaimManagement,
    iconClassName: "icon-qmBase-ClaimManagement",
    backgroundClassName: "orangeFunGradient",
    chooseTemplate: ClaimRoutePaths.ChooseTemplate,
    create: ClaimRoutePaths.CreatePage,
    edit: ClaimRoutePaths.EditPage,
    homePage: ClaimRoutePaths.HomePage,
    list: ClaimRoutePaths.IndexPage,
    dashboard: QualityMonitorRoutePaths.Claims,
    settings: SettingsRoutePaths.ClaimManagementHome,
  },
  {
    pbdModule: PbdModule.OpportunityManagement,
    iconClassName: "icon-qmBase-OpportunitiesAndRisks",
    backgroundClassName: "christmasGradient",
    create: OpportunityRoutePaths.CreatePage,
    edit: OpportunityRoutePaths.EditPage,
    list: OpportunityRoutePaths.IndexPage,
    homePage: OpportunityRoutePaths.HomePage,
    dashboard: QualityMonitorRoutePaths.Opportunities,
    settings: SettingsRoutePaths.OpportunityManagementHome,
  },
  {
    pbdModule: PbdModule.KnowledgeBase,
    iconClassName: "icon-qmBase-KnowledgeBase",
    backgroundClassName: "twitchGradinet",
    create: ArticleRoutePaths.CreatePage(PbdModule.KnowledgeBase),
    edit: ArticleRoutePaths.EditPage(PbdModule.KnowledgeBase),
    list: ArticleRoutePaths.IndexPage(PbdModule.KnowledgeBase),
    homePage: ArticleRoutePaths.HomePage(PbdModule.KnowledgeBase),
    dashboard: QualityMonitorRoutePaths.KnowledgeBase,
  },
  {
    pbdModule: PbdModule.MaintenanceManagement,
    iconClassName: "icon-qmBase-InventoryItem",
    backgroundClassName: "qmBaseDarkToLightBlueGradient",
    create: MaintenanceManagementRoutePaths.CreateInventoryItem,
    edit: MaintenanceManagementRoutePaths.EditPageInventoryItem,
    list: MaintenanceManagementRoutePaths.IndexPage,
    homePage: MaintenanceManagementRoutePaths.HomePage,
    dashboard: QualityMonitorRoutePaths.Maintenance,
    settings: SettingsRoutePaths.MaintenanceManagementHome,
  },
  {
    pbdModule: PbdModule.ContactManagement,
    iconClassName: "icon-qmBase-Contacts",
    backgroundClassName: "meridianGradient",
    list: CrmRoutePaths.IndexPageOrganisations,
  },
  {
    pbdModule: PbdModule.Crm,
    iconClassName: "icon-qmBase-Contacts",
    backgroundClassName: "meridianGradient",
    list: CrmRoutePaths.IndexPageOrganisations,
    homePage: CrmRoutePaths.HomePage,
    dashboard: QualityMonitorRoutePaths.Crm,
    settings: SettingsRoutePaths.CrmHome,
  },
  {
    pbdModule: PbdModule.DefectManagement,
    iconClassName: "icon-qmBase-Defects",
    backgroundClassName: "orangeCoralGradient",
    create: DefectRoutePaths.CreatePage,
    edit: DefectRoutePaths.EditPage,
    list: DefectRoutePaths.IndexPage,
    homePage: DefectRoutePaths.HomePage,
    settings: SettingsRoutePaths.DefectManagementHome,
  },
  {
    pbdModule: PbdModule.Products,
    iconClassName: "icon-qmBase-Products",
    backgroundClassName: "qmBaseDarkToLightBlueGradient",
    create: ProductRoutePaths.CreatePage,
    edit: ProductRoutePaths.EditPage,
    list: ProductRoutePaths.IndexPage,
    homePage: ProductRoutePaths.HomePage,
    settings: SettingsRoutePaths.ProductsHome,
  },
  {
    pbdModule: PbdModule.QualityMonitor,
    iconClassName: "icon-qmBase-QualityMonitor",
    backgroundClassName: "qmBaseDarkToLightBlueGradient",
    homePage: QualityMonitorRoutePaths.HomePage,
  },
  {
    pbdModule: PbdModule.CustomForms,
    iconClassName: "far fa-edit",
    backgroundClassName: "mojitoGradient",
    edit: FormRoutePaths.DetailsPageCustomForm,
    homePage: FormRoutePaths.HomePage,
    list: FormRoutePaths.IndexPageCustomForm,
    dashboard: QualityMonitorRoutePaths.CustomForms,
  },
  {
    pbdModule: PbdModule.QualificationMatrix,
    iconClassName: "icon-qmBase-QualificationMatrix",
    backgroundClassName: "mildGradient",
    homePage: QualificationMatrixRoutePaths.HomePage,
    // settings: SettingsRoutePaths.FunctionsAndQualificationsHome,
  },
  {
    pbdModule: PbdModule.AbsencePlanner,
    iconClassName: "icon-qmBase-AbsencePlanner",
    backgroundClassName: "vanusaGradient",
    homePage: AbsencePlannerRoutePaths.HomePage,
    list: AbsencePlannerRoutePaths.AbsenceRequestIndex,
    create: AbsencePlannerRoutePaths.AbsenceRequestCreate,
    edit: AbsencePlannerRoutePaths.AbsenceRequestEdit,
    settings: SettingsRoutePaths.AbsencePlannerHomePage,
  },

  {
    pbdModule: PbdModule.Admin,
    iconClassName: "icon-qmBase-Admin",
    backgroundClassName: "sweetMorningGradient",
    homePage: AdminRoutePaths.HomePage,
  },
  {
    pbdModule: PbdModule.Blog,
    iconClassName: "icon-qmBase-Blogs",
    backgroundClassName: "kimboyBlueGradient",
    edit: ArticleRoutePaths.EditPage(PbdModule.Blog),
    list: ArticleRoutePaths.IndexPage(PbdModule.Blog),
    homePage: ArticleRoutePaths.HomePage(PbdModule.Blog),
    dashboard: QualityMonitorRoutePaths.Blog,
  },
  {
    pbdModule: PbdModule.Settings,
    iconClassName: "fas fa-cogs",
    backgroundClassName: "qmBaseDarkToLightBlueGradient",
    homePage: SettingsRoutePaths.Home,
  },
  {
    pbdModule: PbdModule.Store,
    iconClassName: "fas fa-shopping-basket",
    backgroundClassName: "storeGradient",
    homePage: StoreRoutePaths.HomePage,
  },
  {
    pbdModule: PbdModule.TenantManagement,
    iconClassName: "",
    backgroundClassName: "",
    homePage: EmployeeRoutePaths.HomePage,
    create: EmployeeRoutePaths.CreatePage,
    edit: EmployeeRoutePaths.EditPage,
    list: EmployeeRoutePaths.IndexPage,
  },
  {
    pbdModule: PbdModule.Qualification,
    iconClassName: "",
    backgroundClassName: "",
    edit: QualificationMatrixRoutePaths.DetailsPageQualification,
    list: QualificationMatrixRoutePaths.IndexPageQualification,
  },
  {
    pbdModule: PbdModule.CompanyFunction,
    iconClassName: "",
    backgroundClassName: "",
    edit: QualificationMatrixRoutePaths.DetailsPageCompanyFunction,
    list: QualificationMatrixRoutePaths.IndexPageCompanyFunction,
  },
  {
    pbdModule: PbdModule.ProjectManagement,
    backgroundClassName: "",
    iconClassName: "",
    create: TaskManagementRoutePaths.CreateProjectPage,
    edit: TaskManagementRoutePaths.EditProjectPage,
    homePage: TaskManagementRoutePaths.IndexProjectsPage,
    list: TaskManagementRoutePaths.IndexProjectsPage,
  },
  {
    pbdModule: PbdModule.ToDoManagement,
    backgroundClassName: "",
    iconClassName: "",
    create: TaskManagementRoutePaths.CreateToDoPage,
    edit: TaskManagementRoutePaths.EditTodoPage,
    list: TaskManagementRoutePaths.IndexTodoPage,
    homePage: TaskManagementRoutePaths.IndexTodoPage,
  },
];
