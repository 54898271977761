import { DateTime } from "luxon";

import { AbsenceRequestQueryParameters } from "@/services/absencePlanner/models/query-parameters";
import { ClaimQueryParameters } from "@/services/claims/models/queryParameters";

import {
  ArticleRevisionReviewsQueryParameters,
  ArticlesQueryParameters,
} from "../../../../../pbdServices/services/Articles/models/query-parameters";
import { GoalQueryParameters } from "../../../../../pbdServices/services/Goals/models/query-parameters";
import { EmployeeIdeaQueryParameters } from "../../../../../pbdServices/services/IdeaManagement/models/query-parameters";
import { InventoryItemQueryParameters } from "../../../../../pbdServices/services/InventoryItems/models/query-parameters";
import { OpportunityQueryParameters } from "../../../../../pbdServices/services/Opportunities/models/query-parameter";
import { TenantQueryParameters } from "../../../../../pbdServices/services/Tenants/models/query-parameters";
import { ToDoQueryParameters } from "../../../../../pbdServices/services/ToDos/models/query-parameter";
import { TrainingQueryParameters } from "../../../../../pbdServices/services/Trainings/models/query-parameters";

export type FilterKey =
  | keyof ToDoQueryParameters
  | keyof ClaimQueryParameters
  | keyof GoalQueryParameters
  | keyof ArticlesQueryParameters
  | keyof EmployeeIdeaQueryParameters
  | keyof InventoryItemQueryParameters
  | keyof OpportunityQueryParameters
  | keyof TrainingQueryParameters
  | keyof TenantQueryParameters
  | keyof ArticleRevisionReviewsQueryParameters
  | keyof AbsenceRequestQueryParameters;

export const FilterKeysNotToRender: FilterKey[] = ["fields"];

export type FilterValue = string | string[] | number | number[] | DateTime | boolean;

export type ActiveFilterType = Partial<Record<FilterKey, FilterValue>>;

export const FilterMapDictionary: Partial<Record<FilterKey, string>> = {
  status: "Status",
  createdById: "Created by",
  responsibleId: "Responsible",
  approvedById: "Approved by",
  reviewerId: "Reviewer",
  createdFrom: "Created from",
  createdTo: "Created to",
  tags: "Tags",
  categoryId: "Category",
  isEmployee: "Employee",
  isApplicationUser: "User",
  namedQuery: "Query",
  applicationUserGroupId: "User group",
  onlyLatestRevision: "Only latest revision",
  publishedFrom: "Published from",
  publishedTo: "Published to",
  departmentId: "Department",
  departmentPositionId: "Position",
  addressId: "Address",
  addressRoomId: "Room",
  absentForId: "Absent",
  absencePolicyId: "Absence policy",
};

export interface IFilterProps {
  activeFilters: ActiveFilterType;
  onDelete: (key: FilterKey) => void;
}
