import { Badge, CloseButton } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import useSWR from "swr";

import { useAppContext } from "@/components/contexts/appContext";
import { useAPIServices } from "@/services/serviceContext";

import ActiveFilterBadgeValue from "./activeFilterBadgeValue";
import { FilterKey, FilterKeysNotToRender, FilterMapDictionary, FilterValue } from "./filterKey";

interface IProps {
  filterKey: FilterKey;
  values: FilterValue;
  onClose?: (key: FilterKey) => void;
  separator?: string;
}

function ActiveFilterBadge(props: IProps) {
  const { t } = useTranslation();
  const { currentApp } = useAppContext();
  const { values, onClose, separator, filterKey } = props;
  const { activeFilterService } = useAPIServices();

  const { data } = useSWR([filterKey, values, currentApp], () =>
    activeFilterService.getDataByTitle(filterKey, values, currentApp),
  );

  if (FilterKeysNotToRender.includes(filterKey)) return null;

  return (
    <Badge bg="secondary" pill className="d-flex align-items-center">
      <span>
        {t(FilterMapDictionary[filterKey] ?? filterKey)}:{" "}
        <ActiveFilterBadgeValue values={data ?? values} separator={separator} />
      </span>
      {onClose && <CloseButton variant="white" onClick={() => onClose(filterKey)} />}
    </Badge>
  );
}

export default ActiveFilterBadge;
