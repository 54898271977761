import { lazy } from "react";
import { RouteObject } from "react-router-dom";

import { PbdRouteModel } from "../../ClientApp/router/pbdRouteModel";
import { PbdRoles } from "../../services/Authz/PbdRoles";

import { ClaimRoutePaths } from "./claimRoutePaths";

const defaultRouteParams: Partial<PbdRouteModel> = {
  roles: [PbdRoles.ClaimManagement_Standard, PbdRoles.ClaimManagement_ModuleAdmin],
};

export const claimLazyRoutes: RouteObject[] = [
  {
    path: ClaimRoutePaths.HomePage,
    Component: lazy(() => import("./pages/homePageClaims")),
    ...defaultRouteParams,
  },
  {
    path: ClaimRoutePaths.IndexPage,
    Component: lazy(() => import("./pages/indexPageClaims")),
    ...defaultRouteParams,
  },
  {
    path: ClaimRoutePaths.CreatePage,
    Component: lazy(() => import("./pages/createPageClaims")),
    ...defaultRouteParams,
  },
  {
    path: ClaimRoutePaths.EditPage,
    Component: lazy(() => import("./pages/detailsPageClaim")),
    ...defaultRouteParams,
  },
  {
    path: ClaimRoutePaths.Create8DReport,
    Component: lazy(() => import("../../ClientApp/eightDReports/pages/createPage8DReport")),
    ...defaultRouteParams,
  },
  {
    path: ClaimRoutePaths.DetailsPage8DReport,
    Component: lazy(() => import("../../ClientApp/eightDReports/pages/detailsPage8DReports")),
    ...defaultRouteParams,
  },
  {
    path: ClaimRoutePaths.IndexPage8DReports,
    Component: lazy(() => import("../../ClientApp/eightDReports/pages/indexPage8DReports")),
    ...defaultRouteParams,
  },
];
