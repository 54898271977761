import { RouteObject } from "react-router-dom";

import { ProtectedRoute } from "@/components/contexts/authorizationWrapper";
import { paths } from "@/config/paths";
import { PbdModule } from "@/generatedCode/pbd-core/pbd-core-api";

import { absencePlannerLazyRoutes } from "../ClientApp/absencePlanner/absencePlannerRoutes";
import { accountObjects } from "../ClientApp/account/accountRoutes";
import { adminRoutesConfig } from "../ClientApp/admin/adminRoutes";
import { ArticleRoutePaths } from "../ClientApp/articles/articleRoutePaths";
import { articleLazyRoutes, documentManagementLazyRoutes } from "../ClientApp/articles/articleRoutes";
import EditPageBpmn from "../ClientApp/articles/bpmn/editPageBpmn";
import DetailsPageArticleRedirect from "../ClientApp/articles/pages/detailsPageArticleRedirect";
import { auditLazyRoutes } from "../ClientApp/audits/auditRoutes";
import { crmLazyRoutes } from "../ClientApp/crm/crmRoutes";
import { defectLazyRoutes } from "../ClientApp/defects/defectRoutes";
import { devLazyRoutes } from "../ClientApp/dev/devRoutes";
import { employeeLazyRoutes } from "../ClientApp/employees/employeeRoutes";
import { customFormLazyRoutes } from "../ClientApp/forms/formRoutes";
import { ideaLazyRoutes } from "../ClientApp/ideaManagement/ideaManagementRoutes";
import { maintenanceManagementLazyRoutes } from "../ClientApp/maintenanceManagement/maintenanceManagementRoutes";
import { opportunityLazyRoutes } from "../ClientApp/opportunities/opportunityRoutes";
import { PrintRoutePaths } from "../ClientApp/prints/printRoutePaths";
import { printRoutesObject } from "../ClientApp/prints/printRoutes";
import { profileLazyRoutes } from "../ClientApp/profile/profileRoutes";
import { qualificationMatrixLazyRoutes } from "../ClientApp/qualificationMatrix/qualificationMatrixRoutes";
import { dashboardLazyRoutes } from "../ClientApp/qualityMonitor/qualityMonitorRoutes";
import { settingRoutesConfig } from "../ClientApp/settings/settingsRoutes";
import { ErrorPage } from "../ClientApp/shared/pages/errorPage";
import AccessDeniedPage from "../ClientApp/startpage/pages/accessDeniedPage";
import { startpageRoutes } from "../ClientApp/startpage/startpageRoutes";
import { storeLazyRoutes } from "../ClientApp/store/storeRoutes";
import { taskManagementLazyRoutes } from "../ClientApp/taskManagement/taskManagementRoutes";
import { trainingLazyRoutes } from "../ClientApp/trainings/trainingRoutes";
import LayoutAccount from "../components/layouts/layoutAccount";
import LayoutMain from "../components/layouts/layoutMain";
import LayoutPrint from "../components/layouts/layoutPrint";
import LayoutWide from "../components/layouts/layoutWide";
import { claimLazyRoutes } from "../features/claims/claimRoutes";
import { goalLazyRoutes } from "../features/goals/goalRoutes";
import { issueRouteObject } from "../features/issues/issueApp";
import { productLazyRoutes } from "../features/products/productRoutes";

import { Root } from "./root";

const printRouteObject: RouteObject = {
  path: PrintRoutePaths.HomePage,
  element: <LayoutPrint />,
  children: printRoutesObject,
};

const accountRouteObject: RouteObject = {
  path: "/Account",
  element: <LayoutAccount />,
  errorElement: <ErrorPage />,
  children: accountObjects,
};

export const routesConfig: RouteObject[] = [
  accountRouteObject,
  {
    element: (
      <ProtectedRoute>
        <Root />
      </ProtectedRoute>
    ),
    children: [
      {
        element: <LayoutMain />,
        children: [
          ...startpageRoutes,
          ...profileLazyRoutes,
          ...productLazyRoutes,
          ...dashboardLazyRoutes,
          ...goalLazyRoutes,
          ...taskManagementLazyRoutes,
          ...defectLazyRoutes,
          ...customFormLazyRoutes,
          ...trainingLazyRoutes,
          ...ideaLazyRoutes,
          ...claimLazyRoutes,
          ...opportunityLazyRoutes,
          ...auditLazyRoutes,
          ...crmLazyRoutes,
          ...employeeLazyRoutes,
          ...maintenanceManagementLazyRoutes,
          ...absencePlannerLazyRoutes,
          ...storeLazyRoutes,
          ...qualificationMatrixLazyRoutes,
          ...adminRoutesConfig,
          ...settingRoutesConfig,
          ...devLazyRoutes,
          issueRouteObject,
          {
            path: ArticleRoutePaths.DetailsPageRedirect,
            element: <DetailsPageArticleRedirect />,
          },
          {
            path: PbdModule.Article,
            children: articleLazyRoutes.filter((x) => x.path?.includes("Details/:id")),
          },
          {
            path: PbdModule.Blog,
            children: articleLazyRoutes,
          },
          {
            path: PbdModule.DocumentManagement,
            children: documentManagementLazyRoutes,
          },
          {
            path: PbdModule.KnowledgeBase,
            children: articleLazyRoutes,
          },
        ],
      },
      {
        element: <LayoutWide />,
        children: [PbdModule.Blog, PbdModule.DocumentManagement, PbdModule.KnowledgeBase].map((x) => ({
          path: ArticleRoutePaths.EditBpmnPage(x),
          element: <EditPageBpmn />,
        })),
      },
      printRouteObject,
      { path: paths.auth.accessDenied.path, element: <AccessDeniedPage /> },
    ],
  },
  { path: paths.error.path, element: <ErrorPage /> },
  {
    path: "*",
    lazy: async () => {
      const { NotFoundPage } = await import("./routes/notFoundPage");
      return { Component: NotFoundPage };
    },
  },
];
