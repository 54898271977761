import { ErrorMessage, FieldHookConfig, useField } from "formik";
import { DateTime } from "luxon";
import { Form } from "react-bootstrap";

import { DateTimeLuxonHelpers } from "@/utils/dateTimeLuxonHelpers";

import { IBaseFormElementProps } from "./baseFormElementProps";
import { CustomFormLabel } from "./customFormLabel";

interface DateInputProps {
  minDate?: Date;
  maxDate?: Date;
}

export function FormikLuxonDateInputGroup(
  props: FieldHookConfig<DateTime | string | undefined | null> & IBaseFormElementProps & DateInputProps,
) {
  const { formText, id } = props;
  const [field, meta] = useField(props);
  const localId = id ?? props.name;

  const invalid = meta.error != undefined && meta.touched;

  return (
    <Form.Group className="mb-3" controlId={localId}>
      <CustomFormLabel {...props} />
      <Form.Control
        {...field}
        isInvalid={invalid}
        type="date"
        value={field.value ? DateTimeLuxonHelpers.convertFromUnknownToDateTime(field.value).toISODate() : undefined}
        min={props.minDate?.toISOString()}
        max={props.maxDate?.toISOString()}
      />
      <ErrorMessage {...field}>
        {(msg) => (
          <Form.Control.Feedback type="invalid" className="order-last">
            {msg}
          </Form.Control.Feedback>
        )}
      </ErrorMessage>
      {formText && <Form.Text muted>{formText}</Form.Text>}
    </Form.Group>
  );
}
