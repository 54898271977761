import { ISmartViewDTO, ISmartViewsControllerClient, PbdModule } from "@/generatedCode/pbd-core/pbd-core-api";

import { AbsencePlannerRoutePaths } from "../../../ClientApp/absencePlanner/absencePlannerRoutePaths";
import { ArticleRoutePaths } from "../../../ClientApp/articles/articleRoutePaths";
import { AuditRoutePaths } from "../../../ClientApp/audits/auditRoutePaths";
import { CrmRoutePaths } from "../../../ClientApp/crm/crmRoutePaths";
import { DefectRoutePaths } from "../../../ClientApp/defects/defectRoutePaths";
import { EmployeeRoutePaths } from "../../../ClientApp/employees/employeeRoutePaths";
import { FormRoutePaths } from "../../../ClientApp/forms/formRoutePaths";
import { IdeaManagementRoutePaths } from "../../../ClientApp/ideaManagement/ideaManagementRoutePaths";
import { MaintenanceManagementRoutePaths } from "../../../ClientApp/maintenanceManagement/maintenanceManagementRoutePaths";
import { OpportunityRoutePaths } from "../../../ClientApp/opportunities/opportunityRoutePaths";
import { QualificationMatrixRoutePaths } from "../../../ClientApp/qualificationMatrix/qualificationMatrixRoutePaths";
import { TaskManagementRoutePaths } from "../../../ClientApp/taskManagement/taskManagementRoutePaths";
import { TrainingRoutePaths } from "../../../ClientApp/trainings/trainingRoutePaths";
import { ClaimRoutePaths } from "../../../features/claims/claimRoutePaths";
import { GoalRoutePaths } from "../../../features/goals/goalRoutePaths";
import { ProductRoutePaths } from "../../../features/products/productRoutePaths";
import StringHelpers from "../../../utils/stringHelpers";
import { MeAsUser } from "../UserSettings/models/me-as-user";

export default class SmartViewService {
  api: ISmartViewsControllerClient;
  constructor(controller: ISmartViewsControllerClient) {
    this.api = controller;
  }

  async getAllMapped(module: PbdModule, meAsUser: MeAsUser) {
    const smartViews = await this.api.getAllQuery({ module });
    const sorted = smartViews.sort((a, b) => a.name.localeCompare(b.name));
    const mySmartViews = sorted.filter((x) => x.createdById == meAsUser.tenant.id);
    const globalSmartViews = sorted.filter((x) => x.isShared);
    const allVisible = sorted.filter((x) => x.createdById == meAsUser.tenant.id || x.isShared);
    return { allVisible, mySmartViews, globalSmartViews };
  }

  static readonly smartViewId = "smartViewId";

  /**
   * Return the initial query value without a leading **?**
   */
  static getInitialQueryValues(query: string) {
    const qs = new URLSearchParams(query);

    qs.delete(this.smartViewId);
    return qs.toString();
  }

  static getSmartViewQueryString(dto: ISmartViewDTO): string {
    const smartViewId = `smartViewId=${dto.id}`;
    let query = StringHelpers.isNullOrWhitespace(dto.query) ? `?${smartViewId}` : `${dto.query}&${smartViewId}`;
    if (!query.startsWith("?")) {
      query = `?${query}`;
    }
    return query;
  }

  static linkTo(module: PbdModule, dto: ISmartViewDTO) {
    const currentLocation = window.location.pathname;
    const query = SmartViewService.getSmartViewQueryString(dto);
    if (module == PbdModule.TenantQualificationDone) {
      if (currentLocation.toString().startsWith(QualificationMatrixRoutePaths.QualificationRequirementsIndex)) {
        return `${QualificationMatrixRoutePaths.QualificationRequirementsIndex}/${query}`;
      }
      return `${QualificationMatrixRoutePaths.IndexPageTenantQualificationsDone}/${query}`;
    } else if (module == PbdModule.CompanyFunctionMatrix) {
      return `${QualificationMatrixRoutePaths.MatrixPageCompanyFunction}/${query}`;
    } else if (module == PbdModule.QualificationMatrix) {
      return `${QualificationMatrixRoutePaths.MatrixPageQualification}/${query}`;
    } else if (module == PbdModule.ToDoManagement) {
      return `${TaskManagementRoutePaths.IndexTodoPage}/${query}`;
    } else if (module == PbdModule.ProjectManagement) {
      return `${TaskManagementRoutePaths.IndexProjectsPage}/${query}`;
    } else if (module == PbdModule.IdeaManagement) {
      return `${IdeaManagementRoutePaths.IndexPage}/${query}`;
    } else if (module == PbdModule.ClaimManagement) {
      return `${ClaimRoutePaths.IndexPage}/${query}`;
    } else if (module == PbdModule.AuditManagement) {
      return `${AuditRoutePaths.IndexPage}/${query}`;
    } else if (module == PbdModule.GoalManagement) {
      return `${GoalRoutePaths.IndexPage}/${query}`;
    } else if (module == PbdModule.TrainingManagement) {
      return `${TrainingRoutePaths.IndexPage}/${query}`;
    } else if (module == PbdModule.AbsencePlanner) {
      return `${AbsencePlannerRoutePaths.AbsenceRequestIndex}/${query}`;
    } else if (module == PbdModule.MaintenanceManagement) {
      return `${MaintenanceManagementRoutePaths.IndexPage}/${query}`;
    } else if (module == PbdModule.OpportunityManagement) {
      return `${OpportunityRoutePaths.IndexPage}/${query}`;
    } else if (module == PbdModule.DefectManagement) {
      return `${DefectRoutePaths.IndexPage}/${query}`;
    } else if (module == PbdModule.Products) {
      return `${ProductRoutePaths.IndexPage}/${query}`;
    } else if (module == PbdModule.Crm) {
      return `${CrmRoutePaths.IndexPageOrganisations}/${query}`;
    } else if (module == PbdModule.Employees) {
      return `${EmployeeRoutePaths.IndexPage}/${query}`;
    } else if (module == PbdModule.TenantManagement) {
      return `${CrmRoutePaths.IndexPageTenants}/${query}`;
    } else if (module == PbdModule.CustomForms) {
      return `${FormRoutePaths.IndexPageCustomForm}/${query}`;
    } else if ([PbdModule.Blog, PbdModule.KnowledgeBase, PbdModule.DocumentManagement].includes(module)) {
      return `${ArticleRoutePaths.IndexPage(module)}/${query}`;
    }
    console.warn(module);
    throw Error("Module not defined");
  }
}
