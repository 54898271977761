import "i18next";
import { Settings } from "luxon";
import React, { createContext } from "react";
import { Toaster } from "react-hot-toast";
import useSWRImmutable from "swr/immutable";

import { IIssueDTO } from "@/generatedCode/admin/qmAdminApi";
import {
  IAppDefinition,
  IApplicationGroupDTO,
  IBillingPropertiesDTO,
  PbdModule,
} from "@/generatedCode/pbd-core/pbd-core-api";
import { useApps } from "@/hooks/useApps";
import { useCurrentAppHook } from "@/hooks/useCurrentAppHook";
import { useKeyboardHook } from "@/hooks/useKeyboardHook";
import { useModuleSettingsHook } from "@/hooks/useModuleSettingsHook";
import { useSubscriptionsHook } from "@/hooks/useSubscriptionsHook";

import ErrorModal, {
  ApiErrorMessage,
  LegacyErrorMessage,
  QmErrorMessage,
} from "../../ClientApp/shared/components/modals/errorModal";
import { IProductConfigInterface } from "../../Constants/productConfig";
import { OrderDisplayName } from "../../Models/Enums/OrderDisplayName";
import { ErrorMessage } from "../../Models/Errors/ErrorMessage";
import { AppVM } from "../../pbdServices/Models/App/AppVM";
import { ApiError } from "../../pbdServices/services/Api/models/api-error";
import { AppSettingsVm } from "../../pbdServices/services/ModuleSettings/models/appModuleSettings";
import { MeAsUser } from "../../pbdServices/services/UserSettings/models/me-as-user";
import { getCurrentCulture } from "../../services/i18n/cultureSettingService";
import { detectBrowser } from "../../services/pbdGlobalSettings";
import { useAPIs } from "../../services/serviceContext";
import { buildYupLocale } from "../../services/validation/buildYupLocale";

import "@/utils/alphaNumericSort";
import { useAuthenticationContext } from "./authenticationContext";
import "./chartImport";
import { ConfirmationServiceProvider } from "./modalConfirmationContext";
import { NotificationProvider } from "./notificationContext";
import "./yupImport";

buildYupLocale();

export interface CultureInfo {
  /**user language: 'en' */
  language: string;
  /**user culture info: 'en-US' */
  cultureInfo: string;
  timeZone: string;
  browser?: Bowser.Parser.ParsedResult;
}

export interface AppContextInterface {
  /**This info is always available and will be awaited in the appContext */
  meAsUser: MeAsUser;
  availableModules: AppVM[];
  appDefinitions: IAppDefinition[];
  groups: IApplicationGroupDTO[];
  orderDisplayName?: OrderDisplayName;

  //TODO: Legacy deprecate it later
  setErrorMessage: (message: ErrorMessage) => void;
  handleApiError: (error: ApiError) => void;

  cultureInfo: CultureInfo;
  issues?: IIssueDTO[];
  /**This contains the specific settings for each app */
  appSettings: AppSettingsVm;
  productConfig: IProductConfigInterface;
  /**Current app. No final implementation just for articles area */
  currentApp: PbdModule;
  billingProps: IBillingPropertiesDTO;
}

const cultureInfoDefaultValue: CultureInfo = {
  language: "en",
  cultureInfo: "en-US",
  timeZone: "Europe/Berlin",
};

// const useAppContext=()=>{
//   const object=useContext(Context);
//   if(!object){
//     throw new Error("useCtx from AppContext must be inside a Provider with a value");
//   }
//   return object;
// }

function createCtx() {
  const ctx = createContext<AppContextInterface | null>(null);
  function useCtx() {
    const c = React.useContext(ctx);
    if (!c) throw new Error("useCtx from AppContext must be inside a Provider with a value");
    return c;
  }
  return [useCtx, ctx.Provider] as const;
}

const [useAppContext, CtxProvider] = createCtx();

const getCultureInfo = (defaultValue: CultureInfo) => {
  const currentCulture = getCurrentCulture();
  Settings.defaultLocale = currentCulture;
  defaultValue.language = currentCulture;
  defaultValue.cultureInfo = currentCulture;
  defaultValue.browser = detectBrowser();
  return defaultValue;
};

interface IProps {
  children: React.ReactNode;
}

export function AppContextProvider({ children }: IProps) {
  const { meAsUser, user } = useAuthenticationContext();
  const { available, productConfig, appDefinitions } = useApps(meAsUser);
  const { groupsApi } = useAPIs();
  const { data: groups } = useSWRImmutable("api/groups", () => groupsApi.getAll());
  const { data: moduleSettingsResp } = useModuleSettingsHook();
  const { data: billingProps } = useSubscriptionsHook();
  const { currentApp } = useCurrentAppHook();
  useKeyboardHook(currentApp);

  const [errorModal, setErrorModal] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState<QmErrorMessage>();
  const cultureInfo = React.useMemo(() => {
    return getCultureInfo(cultureInfoDefaultValue);
  }, []);

  const handleSetErrorMessage = React.useCallback((message: ErrorMessage) => {
    setErrorMessage(new LegacyErrorMessage(message));
    setErrorModal(true);
  }, []);

  const handleApiError = React.useCallback((apiError: ApiError) => {
    setErrorMessage(new ApiErrorMessage(apiError));
    setErrorModal(true);
  }, []);

  const toggleErrorModal = () => {
    setErrorModal(false);
    setErrorMessage(undefined);
  };

  if (!available || !appDefinitions || !groups || !moduleSettingsResp || !productConfig || !billingProps || !meAsUser)
    return null;

  return (
    <CtxProvider
      value={{
        meAsUser,
        availableModules: available,
        setErrorMessage: handleSetErrorMessage,
        handleApiError,
        cultureInfo: cultureInfo,
        productConfig,
        billingProps,
        groups: groups,
        appSettings: moduleSettingsResp,
        currentApp,
        appDefinitions,
      }}
    >
      <NotificationProvider>
        <ConfirmationServiceProvider>{children}</ConfirmationServiceProvider>
      </NotificationProvider>
      <Toaster />
      <ErrorModal modal={errorModal} toggle={toggleErrorModal} error={errorMessage} />
    </CtxProvider>
  );
}

export { CtxProvider, useAppContext };
