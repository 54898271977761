import { PbdStatus } from "@/generatedCode/admin/qmAdminApi";
import { IAbsencePoliciesControllerClient, IAbsenceTypesControllerClient } from "@/generatedCode/pbd-core/pbd-core-api";

import {
  IPrerequisitesReturnType,
  IPrerequisitesService,
  IPrerequisitesWrapper,
} from "../../ClientApp/prerequisitesModals/prerequisitesModal";
import { SettingsRoutePaths } from "../../ClientApp/settings/settingsRoutePaths";

export class AbsencePlannerService implements IPrerequisitesService {
  private _absenceTypesApi: IAbsenceTypesControllerClient;
  private _absencePolicyApi: IAbsencePoliciesControllerClient;
  constructor(absenceTypesApi: IAbsenceTypesControllerClient, absencePolicyApi: IAbsencePoliciesControllerClient) {
    this._absenceTypesApi = absenceTypesApi;
    this._absencePolicyApi = absencePolicyApi;
  }

  async getAllPrerequisites(): Promise<IPrerequisitesWrapper> {
    const policies = await this._absencePolicyApi.getAllQuery({ take: 1 });
    const types = await this._absenceTypesApi.getAllQuery({ take: 1 });
    const checks: IPrerequisitesReturnType[] = [
      {
        id: "type",
        title: "Absence types",
        status: types.length == 0 ? PbdStatus.Open : PbdStatus.Completed,
        route: SettingsRoutePaths.IndexPageAbsenceType,
      },
      {
        id: "policies",
        title: "Absence policies",
        status: policies.length == 0 ? PbdStatus.Open : PbdStatus.Completed,
        route: SettingsRoutePaths.IndexPageAbsencePolicy,
      },
    ];
    return {
      checks,
      actionRequired: checks.find((x) => x.status != PbdStatus.Completed) != undefined,
    };
  }
}
