import React, { createContext, useContext } from "react";

import { AppWrapperFallbackLoading } from "@/components/ui/appWrapperFallbackLoading";
import { MeDTO } from "@/generatedCode/pbd-core/pbd-core-api";
import { useUserHook } from "@/hooks/useUserHook";

import { AuthService } from "../../pbdServices/services/Authz/authService";
import { MeAsUser } from "../../pbdServices/services/UserSettings/models/me-as-user";
import UserService from "../../pbdServices/services/Users/userService";

interface ContextInterface {
  meDTO: MeDTO;
  mutate: () => void;
  user: AuthService;
  meAsUser: MeAsUser;
}

const AuthenticationContext = createContext<ContextInterface | null>(null);

const useAuthenticationContext = () => {
  const object = useContext(AuthenticationContext);

  if (!object) {
    throw new Error("useAuthorizationContext must be used within a Provider");
  }
  return object;
};

interface Props {
  children: React.ReactNode;
  renderError?: (error: Error) => React.JSX.Element;
}

/**
 * This context makes sure the authorization state is available. That's it no redirect or any other logic
 */
function AuthenticationContextProvider({
  children,
  renderError = (error: Error) => <>{JSON.stringify(error)}</>,
}: Props) {
  const { data, mutate, isLoading, error, isValidating } = useUserHook();

  if (error) {
    return renderError(error);
  }

  if (!data) return <AppWrapperFallbackLoading title={"Getting authentication state..."} />;

  // if (!data.isAuthenticated && location.pathname != paths.auth.login.path) {
  //   console.log({
  //     pathname: location.pathname,
  //     redirectTo: paths.auth.login.getHref(location.pathname),
  //   });
  //   <Navigate to={paths.auth.login.getHref(location.pathname)} replace />;
  // }

  const meAsUser = UserService.mapToMeAsUser(data);
  const authService = new AuthService(meAsUser);

  return (
    <AuthenticationContext.Provider value={{ meDTO: data, mutate: () => mutate(), meAsUser, user: authService }}>
      {children}
    </AuthenticationContext.Provider>
  );
}

export { AuthenticationContext, AuthenticationContextProvider, useAuthenticationContext };
