import { DateTime } from "luxon";

import XsfrService, { XSFR_TOKEN_NAME } from "../../pbdServices/services/Xsfr/xsfrService";
import AuthStore from "../Authz/AuthStore";

import { ValidationProblemDetails } from "./validationProblemDetails";

export interface IRestResponse<T> {
  isError: boolean;
  errorContent?: ValidationProblemDetails;
  content?: T;
  statusCode: number;
  success: boolean;
}

export default class RestUtilities {
  /**
   *
   * @returns ?id=1&id2&categoryId=5
   */
  static getQueryString<T extends Record<string, unknown>>(query?: T, excludeTime?: boolean) {
    if (!query) return "";
    let queryString = "";

    if (query != null) {
      if (query.queryString != null && query.queryString != "") {
        queryString = query.queryString as string;
      } else {
        const queryObject: Record<string, unknown> = {};

        for (const key of Object.keys(query)) {
          const value = query[key];
          if (value !== null && value != undefined) {
            if (DateTime.isDateTime(value)) {
              let dateValue = value.toISO();
              if (excludeTime) {
                dateValue = dateValue.split("T")[0];
              }
              queryObject[key] = dateValue;
            } else if (typeof value == "boolean") {
              queryObject[key] = value ? 1 : 0;
            } else {
              queryObject[key] = value;
            }
          }
        }
        const parameters: string[] = [];
        const keys = Object.keys(queryObject);
        for (const key of keys) {
          const valueOfKey = queryObject[key];
          if (Array.isArray(valueOfKey) && valueOfKey.length > 1) {
            for (const element of valueOfKey) {
              parameters.push(encodeURI(`${key}=${element}`));
            }
          } else {
            parameters.push(encodeURI(`${key}=${queryObject[key]}`));
          }
        }
        const parametersStringified = parameters.join("&");
        queryString = `?${parametersStringified}`;
      }
    }

    if (queryString.length > 1900) throw Error("queryString too long. Use simpler query");
    return queryString;
  }

  static get<T>(url: string): Promise<IRestResponse<T>> {
    return RestUtilities.#request<T>("GET", url, null);
  }

  static delete(url: string): Promise<IRestResponse<void>> {
    return RestUtilities.#request<void>("DELETE", url);
  }

  static put<T>(url: string, data: unknown): Promise<IRestResponse<T>> {
    return RestUtilities.#request<T>("PUT", url, data);
  }

  static post<T>(url: string, data: unknown): Promise<IRestResponse<T>> {
    return RestUtilities.#request<T>("POST", url, data);
  }

  static patch<T>(url: string, data: unknown): Promise<IRestResponse<T>> {
    return RestUtilities.#request("PATCH", url, data);
  }

  static #request<T>(method: string, url: string, data?: unknown): Promise<IRestResponse<T>> {
    let isJsonResponse = false;
    let isBadRequest = false;
    let body = data;
    let statusCode = 0;
    const headers = new Headers();
    headers.set("Accept", "application/json");

    if (data) {
      if (data instanceof FormData) {
        if (data.getAll("files").length == 0 && data.getAll("file").length == 0) throw Error("Missing files to upload");
      } else {
        if (typeof data === "object") {
          // console.log(typeof data);
          headers.set("Content-Type", "application/json");
          body = JSON.stringify(data);
        } else {
          // console.log(typeof data);
          headers.set("Content-Type", "application/x-www-form-urlencoded");
        }
      }
    }

    if (method == "POST" || method == "DELETE" || method == "PUT" || method == "PATCH") {
      const token = XsfrService.getXSFRToken();
      headers.set(XSFR_TOKEN_NAME, token.tokenName);
    }

    return fetch(url, {
      method: method,
      headers: headers,
      body: body as string,
    })
      .then((response) => {
        if (response.status == 401) {
          // Unauthorized; redirect to sign-in
          AuthStore.removeToken();
          window.location.replace(`/?expired=1`);
        }
        statusCode = response.status;
        isBadRequest =
          response.status == 400 ||
          response.status == 403 ||
          response.status == 404 ||
          response.status == 405 ||
          response.status == 409 ||
          response.status == 415 ||
          response.status == 413 ||
          response.status == 500;

        const responseContentType = response.headers.get("content-type");
        if (responseContentType && responseContentType.includes("application/json")) {
          isJsonResponse = true;
          return response.json();
        }
        return response.text();
      })
      .then((responseContent) => {
        const response: IRestResponse<T> = {
          statusCode: statusCode,
          isError: isBadRequest,
          errorContent: RestUtilities.#mapError(isBadRequest, isJsonResponse, responseContent),
          content: isBadRequest ? null : responseContent,
          success: !isBadRequest,
        };
        return response;
      })
      .catch((error) => {
        return error;
      });
  }

  static #mapError(isBadRequest: boolean, isJsonResponse: boolean, responseContent: unknown) {
    if (!isBadRequest) return undefined;
    try {
      if (isJsonResponse) {
        const mapped = responseContent as ValidationProblemDetails;
        return RestUtilities.#convertErrorsToCamelCase(mapped);
      } else if (responseContent) {
        const mapped = JSON.parse(responseContent as string) as ValidationProblemDetails;
        return RestUtilities.#convertErrorsToCamelCase(mapped);
      }
      return undefined;
    } catch {
      return undefined;
    }
  }

  static #convertErrorsToCamelCase(dotNetError: ValidationProblemDetails) {
    if (dotNetError.errors) {
      Object.keys(dotNetError.errors).map((key: string) => {
        // For ASP.NET core, the field names are in title case - so convert to camel case
        const fieldName = key.charAt(0).toLowerCase() + key.substring(1);
        dotNetError.errors[fieldName] = dotNetError.errors[key];
        if (key != "" && key != "#" && fieldName != key) {
          delete dotNetError.errors[key];
        }
      });
    }
    return dotNetError;
  }
}
