import { DateTime } from "luxon";

import {
  EntityKey,
  IAuditDTO,
  IAuditRequirementDTO,
  IAuditTypesControllerClient,
  IAuditsControllerClient,
  ICustomFormLinksControllerClient,
  ITenantMinDTO,
  IToDoDTO,
  PbdStatus,
} from "@/generatedCode/pbd-core/pbd-core-api";

import { AuditRoutePaths } from "../../../ClientApp/audits/auditRoutePaths";
import { CrmRoutePaths } from "../../../ClientApp/crm/crmRoutePaths";
import { FormRoutePaths } from "../../../ClientApp/forms/formRoutePaths";
import {
  IPrerequisitesReturnType,
  IPrerequisitesService,
  IPrerequisitesWrapper,
} from "../../../ClientApp/prerequisitesModals/prerequisitesModal";
import { SettingsRoutePaths } from "../../../ClientApp/settings/settingsRoutePaths";
import { AvailableConnection } from "../../../ClientApp/shared/components/connectionElements/generic/available-connection";
import { SearchFilterTypes } from "../../../ClientApp/shared/components/genericSearchFilter/availableSearchFilters";
import { TaskManagementRoutePaths } from "../../../ClientApp/taskManagement/taskManagementRoutePaths";
import { GoalRoutePaths } from "../../../features/goals/goalRoutePaths";
import { BaseExportService } from "../Base/BaseExportService";
import { ExportType } from "../Export/exportService";
import ToDoService from "../ToDos/todoService";

import { AuditKpis } from "./models/audit-kpis";

export interface IAuditWthConnections extends Omit<IAuditDTO, "connectedElements"> {
  link: string;
  connectedElements: IConnectedElements;
}

interface IDictionary<T> {
  [key: string | number]: T;
}

interface IConnection<T> {
  link: string;
  item: T;
}

interface IConnectedElements {
  requirements?: IAuditRequirementDTO[];
  todos: IConnection<IDeadlineTableElementView>[];
  goals: IConnection<IDeadlineTableElementView>[];
  forms: IFormTableElementView[];
  attendees: IConnection<IAttendeeTableElementView>[];
}

interface ITableElementView {
  id: number;
  title: string;
}

interface IDeadlineTableElementView extends ITableElementView {
  responsible?: ITenantMinDTO;
  deadline: string | DateTime;
}

interface IAttendeeTableElementView extends ITableElementView {
  status: string;
}

interface IFormTableElementView extends ITableElementView {
  answers: IConnection<IFormAnswerTableElementView>[];
}

interface IFormAnswerTableElementView {
  id: number;
  attendeeFullName: string;
  isPassed: boolean;
}

export class AuditService extends BaseExportService<IAuditDTO> implements IPrerequisitesService {
  #auditTypesApi: IAuditTypesControllerClient;
  #auditApi: IAuditsControllerClient;
  #customFormLinksApi: ICustomFormLinksControllerClient;

  constructor(
    _auditTypesApi: IAuditTypesControllerClient,
    _auditApi: IAuditsControllerClient,
    _customFormLinksApi: ICustomFormLinksControllerClient,
  ) {
    super("Audits");
    this.#auditTypesApi = _auditTypesApi;
    this.#auditApi = _auditApi;
    this.#customFormLinksApi = _customFormLinksApi;
  }
  // What is the purpose of static method?
  // Since we are not using them outside of this class, we can make them just private methods.
  static #buildLink(route: string, id: number) {
    return `${window.location.protocol}//${window.location.host}${route.replace(":id", id.toString())}`;
  }

  static #generateConnection<T extends { id: number }>(items: T[], route: string) {
    return items.map((x) => {
      const toReturn: IConnection<T> = {
        link: AuditService.#buildLink(route, x.id),
        item: x,
      };
      return toReturn;
    });
  }

  async getByIdWithConnections(id: number, auditRequirements: IAuditRequirementDTO[]): Promise<IAuditWthConnections> {
    const audit = await this.#auditApi.getById(id);

    const auditGoals = await this.#auditApi.getConnectedGoals(id);
    const auditTodos = await this.#auditApi.getConnectedToDos(id);
    const auditForms = await this.#auditApi.getConnectedForms(id);
    const auditAttendees = await this.#auditApi.getAttendees(id);

    const formsAnswersPromises = auditForms.map((x) => this.#customFormLinksApi.getAnswersById(x.customFormLinkId));
    const formsAnswers = await Promise.all(formsAnswersPromises).then((x) => x.flat());

    const formsAnswersDict: IDictionary<IConnection<IFormAnswerTableElementView>[]> = {};

    for (const x of formsAnswers) {
      if (!formsAnswersDict[x.customFormId]) {
        formsAnswersDict[x.customFormId] = [];
      }

      formsAnswersDict[x.customFormId].push(
        AuditService.#generateConnection<IFormAnswerTableElementView>(
          [
            {
              id: x.id,
              attendeeFullName: x.createdBy?.fullName ?? "Unknown",
              isPassed: x.evaluation?.isPassed ?? false,
            },
          ],
          FormRoutePaths.DetailsPageCustomFormAnswer.replace(":id", x.id.toString()),
        )[0],
      );
    }

    if (!audit.category) {
      throw new Error("Audit category is missing");
    }

    const auditType = await this.#auditTypesApi.getById(audit.category.id);
    const requirements: IAuditRequirementDTO[] = [];
    auditType.auditRequirements?.forEach((r) => {
      requirements.push(r);
      const childRequirements = auditRequirements.filter((x) => x.parentAuditRequirementId == r.id);
      requirements.push(...childRequirements);
    });

    const formattedTodos: IDeadlineTableElementView[] = auditTodos.map((x) => {
      return {
        id: x.id,
        responsible: x.responsible,
        title: x.title,
        deadline: x.deadline,
      };
    });

    const formattedGoals: IDeadlineTableElementView[] = auditGoals.map((x) => {
      return {
        id: x.id,
        responsible: x.responsible,
        title: x.title,
        deadline: x.deadline,
      };
    });

    const formattedAuditAttendees: IAttendeeTableElementView[] = auditAttendees.map((x) => {
      return {
        id: x.id,
        title: x.fullName,
        status: x.attendanceStatus.status,
      };
    });

    const formattedForms: IFormTableElementView[] = auditForms.map((x) => {
      const formsAnswers = formsAnswersDict[x.id];
      return {
        id: x.id,
        title: x.title,
        answers: formsAnswers,
      };
    });

    const item: IAuditWthConnections = {
      ...audit,
      link: AuditRoutePaths.EditPage.replace(":id", audit.id.toString()),
      connectedElements: {
        todos: AuditService.#generateConnection(formattedTodos, TaskManagementRoutePaths.EditTodoPage),
        forms: formattedForms,
        goals: AuditService.#generateConnection(formattedGoals, GoalRoutePaths.EditPage),
        attendees: AuditService.#generateConnection(formattedAuditAttendees, CrmRoutePaths.EditPageTenant),
        requirements,
      },
    };
    return item;
  }

  async getAllPrerequisites(): Promise<IPrerequisitesWrapper> {
    const types = await this.#auditTypesApi.getAllQuery({ take: 1 });
    const checks: IPrerequisitesReturnType[] = [
      {
        id: "types",
        title: "Audit categories",
        status: types.length == 0 ? PbdStatus.Open : PbdStatus.Completed,
        route: SettingsRoutePaths.AuditManagementHome,
      },
    ];
    const resp: IPrerequisitesWrapper = {
      checks,
      actionRequired: checks.find((x) => x.status != PbdStatus.Completed) != undefined,
    };
    return resp;
  }
  mapToExport(x: IAuditDTO): ExportType {
    return {
      id: x.id,
      title: x.title,
      responsible: x.responsible?.fullName,
      createdAt: x.createdAt,
      plannedAt: x.plannedAt,
      lastUpdatedAt: x.lastUpdatedAt,
    };
  }

  static getKpis(audits: IAuditDTO[], connectedTodos: IToDoDTO[], totalUrl?: string) {
    const kpis = new AuditKpis(audits, totalUrl);
    kpis.connectedTodos = ToDoService.getKpis(connectedTodos);
    return kpis;
  }

  static get availableFilter() {
    return [
      SearchFilterTypes.Responsible,
      SearchFilterTypes.Status,
      SearchFilterTypes.CreatedAt,
      SearchFilterTypes.Tags,
      SearchFilterTypes.Attendees,
      SearchFilterTypes.IsDeleted,
      SearchFilterTypes.PlannedAt,
      SearchFilterTypes.Category,
      SearchFilterTypes.CustomField,
    ];
  }

  static get getConnections() {
    return [
      new AvailableConnection(EntityKey.ToDo),
      new AvailableConnection(EntityKey.Goal),
      new AvailableConnection(EntityKey.Tenant, "default", undefined, { hideConnectionCard: true }),
      new AvailableConnection(EntityKey.CustomForm, "default", undefined, { hideConnectionCard: true }),
    ];
  }
}
